export enum IAppLanguage {
  English = 'en-US',
  Ukrainian = 'ua-UA',
}

export interface ILang {
  name: 'English' | 'Український';
  code: IAppLanguage;
  selected?: boolean;
}
