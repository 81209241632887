import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesList } from '@models/routes';
import { LoginPageComponent } from './login-page.component';

const routes: Routes = [
  {
    path: RoutesList.Login,
    component: LoginPageComponent,
  },
  {
    path: RoutesList.Register,
    component: LoginPageComponent,
  },
  {
    path: RoutesList.Restore,
    component: LoginPageComponent,
  },
  {
    path: RoutesList.About,
    component: LoginPageComponent,
  },
  {
    path: '',
    component: LoginPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
