import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { IMessage } from '@models/response';

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) {}

  show(toast: IMessage) {
    this.messageService.add(toast);
  }
}
