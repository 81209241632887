import { Subscription } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAppLanguage } from '@models/localization';
import { RoutesList } from '@models/routes';
import { AuthService } from '@services/auth.service';
import { FormBase } from '@util/form-base';

@Component({
  selector: 'app-restore-password-form',
  templateUrl: './restore-password-form.component.html',
  styleUrls: ['./restore-password-form.component.scss'],
})
export class RestorePasswordFormComponent
  extends FormBase
  implements OnInit, OnDestroy
{
  loginLink = `/${RoutesList.Login}`;
  restoreLink: string;
  private activatedRouteSubsctibtion: Subscription;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRouteSubsctibtion = this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params['link']) {
          this.restoreLink = params.link as string;
        }
        this.formGroup = this.fb.group({
          ...(!this.restoreLink && {
            email: ['', [Validators.required, Validators.email]],
          }),
          ...(this.restoreLink && {
            password: ['', [Validators.required, Validators.minLength(5)]],
          }),
        });
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.submitted) {
      const lang = this.translateService.currentLang as IAppLanguage;
      if (this.restoreLink) {
        const payload = {
          password: this.formGroup.value.password as string,
          lang,
        };
        this.authService
          .updateUserPassword(payload, this.restoreLink)
          .subscribe(() => this.router.navigate([RoutesList.Login]));
      } else {
        const payload = {
          email: this.formGroup.value.email as string,
          lang,
        };
        this.authService
          .restoreAccess(payload)
          .subscribe(() => this.formGroup.reset());
      }
    }
  }

  ngOnDestroy(): void {
    this.activatedRouteSubsctibtion?.unsubscribe();
  }
}
