<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <label for="email" class="block text-900 text-xl font-medium mb-2">{{
      "EMAIL_ADDRESS" | translate
    }}</label>

    <input
      type="email"
      id="email"
      placeholder="name@example.com"
      class="w-full md:w-30rem"
      formControlName="email"
      [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }"
      pInputText
    /><br />
    <ng-container
      *ngIf="submitted && formControls.email.errors"
      class="invalid-feedback"
    >
      <small *ngIf="formControls.email.errors.required" class="p-error">
        {{ "EMAIL_REQUIRED" | translate }}
      </small>
      <small *ngIf="formControls.email.errors.email" class="p-error">
        {{ "EMAIL_MUST_BE_VALID" | translate }}
      </small>
    </ng-container>
  </div>

  <div class="mt-3 mb-3">
    <label for="userName" class="block text-900 font-medium text-xl mb-2">{{
      "NAME" | translate
    }}</label>

    <input
      type="text"
      id="userName"
      placeholder="{{ 'NAME' | translate }}"
      formControlName="name"
      pInputText
      class="w-full md:w-30rem"
      [ngClass]="{ 'ng-invalid': submitted && formControls.name.errors }"
      style="padding: 1rem"
    />

    <div *ngIf="submitted && formControls.name.errors" class="invalid-feedback">
      <small *ngIf="formControls.name.errors.required" class="p-error">
        {{ "NAME_REQUIRED" | translate }}
      </small>
    </div>
  </div>

  <div class="mt-3 mb-3">
    <label for="password" class="block text-900 font-medium text-xl mb-2">{{
      "PASSWORD" | translate
    }}</label>
    <!-- TODO: make common password component -->
    <p-password
      id="password"
      weakLabel="{{ 'WEAK_PASSWORD' | translate }}"
      mediumLabel="{{ 'MEDIUM_PASSWORD' | translate }}"
      strongLabel="{{ 'STRONG_PASSWORD' | translate }}"
      promptLabel="{{ 'PICK_PASSWORD' | translate }}"
      formControlName="password"
      placeholder="{{ 'PASSWORD' | translate }}"
      [toggleMask]="true"
      [ngClass]="{ 'ng-invalid': submitted && formControls.password.errors }"
      inputStyleClass="w-full p-3 md:w-30rem"
    >
      <ng-template pTemplate="footer">
        <p-divider></p-divider>
        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
          <li>{{ "LEAST_ONE_LETTER" | translate }}</li>
          <li>{{ "LEAST_ONE_NUMERIC" | translate }}</li>
          <li>{{ "MINIMUM_CHARACTERS" | translate }}</li>
        </ul>
      </ng-template></p-password
    >
    <div
      *ngIf="submitted && formControls.password.errors"
      class="invalid-feedback"
    >
      <small *ngIf="formControls.password.errors.required" class="p-error">
        {{ "PASSWORD_REQUIRED" | translate }}
      </small>
    </div>
  </div>

  <div class="mb-3">
    <label
      for="confirmPassword"
      class="block text-900 text-xl font-medium mb-2"
      >{{ "CONFIRM_PASSWORD" | translate }}</label
    >
    <p-password
      id="confirmPassword"
      weakLabel="{{ 'WEAK_PASSWORD' | translate }}"
      mediumLabel="{{ 'MEDIUM_PASSWORD' | translate }}"
      strongLabel="{{ 'STRONG_PASSWORD' | translate }}"
      promptLabel="{{ 'CONFIRM_PASSWORD' | translate }}"
      formControlName="confirmPassword"
      placeholder="{{ 'PASSWORD' | translate }}"
      [toggleMask]="true"
      [ngClass]="{
        'is-invalid': submitted && formControls.confirmPassword.errors
      }"
      inputStyleClass="w-full p-3 md:w-30rem"
    >
    </p-password>
    <div
      *ngIf="submitted && formControls.confirmPassword.errors"
      class="invalid-feedback"
    >
      <small
        *ngIf="formControls.confirmPassword.errors.required"
        class="p-error"
      >
        {{ "CONFIRM_PASSWORD_REQUIRED" | translate }}
      </small>

      <small
        *ngIf="formControls.confirmPassword.errors.confirmedValidator"
        class="p-error"
      >
        {{ "PASSWORD_CONFIRM_PASSWORD_MUST_MATCH" | translate }}
      </small>
    </div>
  </div>

  <button
    type="submit"
    pButton
    pRipple
    [disabled]="!!formGroup.invalid"
    label="{{ 'SIGN_UP' | translate }}"
    class="w-full p-3 text-xl"
  ></button>

  <div class="flex justify-content-end mt-3 text-600">
    {{ "ALREADY_HAVE_ACCOUNT" | translate }}
    <a
      class="font-medium no-underline ml-2 text-right cursor-pointer"
      [routerLink]="[loginLink]"
      >{{ "LOGIN" | translate }}</a
    >
  </div>
</form>
