<div class="login-menu flex">
  <p-button
    icon="pi pi-user"
    styleClass="p-button-rounded p-button-info p-button-text"
    class="login-menu__btn"
    [ngClass]="{
      'active': isLoginPage,
    }"
    (click)="switchLoginPage(ROUTE.Login)"
  ></p-button>
  <p-button
    icon="pi pi-info"
    styleClass="p-button-rounded p-button-info p-button-text"
    class="login-menu__btn"
    [ngClass]="{
      'active': isAboutPage,
    }"
    (click)="switchLoginPage(ROUTE.About)"
  ></p-button>

  <app-langs class="mr-2 ml-0"></app-langs>
</div>

<div
  class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
>
  <div class="flex flex-column align-items-center justify-content-center">
    <img
      src="assets/layout/images/{{
        layoutService.config.colorScheme === 'light'
          ? 'logo-dark'
          : 'logo-white'
      }}.svg"
      alt="Sakai logo"
      class="mb-5 flex-shrink-0"
    />
    <div class="login-wrapper">
      <div
        class="w-full surface-card py-8 px-5 sm:px-8"
        style="border-radius: 53px"
      >
        <div class="text-center mb-5">
          <ng-container *ngIf="isLoginPage">
            <div class="text-900 text-3xl font-medium mb-3">
              {{ "WELCOME_BACK" | translate }}
            </div>
            <span class="text-600 font-medium">{{
              "SIGN_IN_CONTINUE" | translate
            }}</span></ng-container
          >

          <ng-container *ngIf="isRegisterPage">
            <div class="text-900 text-3xl font-medium mb-3">
              {{ "REGISTRATION_FORM" | translate }}
            </div>
          </ng-container>

          <ng-container *ngIf="isRestorePage">
            <div class="text-900 text-3xl font-medium mb-3">
              {{ "ACCESS_RECOVERY" | translate }}
            </div>
            <!-- <span class="text-600 font-medium">
              {{ "COME_UP_NEW_PASSWORD" | translate }}

              {{ "ENTER_EMAIL" | translate }}
            </span> -->
          </ng-container>

          <app-about *ngIf="isAboutPage"></app-about>
        </div>
        <app-login-form *ngIf="isLoginPage"></app-login-form>

        <app-register-form *ngIf="isRegisterPage"></app-register-form>

        <app-restore-password-form
          *ngIf="isRestorePage"
        ></app-restore-password-form>
      </div>
    </div>
  </div>
</div>
