import { createSelector, createFeatureSelector } from '@ngrx/store';

import { FeatureStateKey } from '@models/store';
import { LoginState } from '../models/store';

export const loginState = createFeatureSelector<LoginState>(
  FeatureStateKey.Login
);

export const isLoggedIn = createSelector(loginState, (state) => {
  return state.isLoggedIn;
});
