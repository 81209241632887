import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { isLoggedIn } from 'app/login/store/login.selectors';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private readonly store: Store
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(isLoggedIn).pipe(map((isLoggedIn) => isLoggedIn));
  }
}
