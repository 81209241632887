import { createReducer, on } from '@ngrx/store';
import { initialLoginState } from './login.state';
import * as LoginActions from './login.action';
import { User } from '@models/auth';
import { LoginState } from '../models/store';

export const loginReducer = createReducer(
  initialLoginState,
  on(LoginActions.login, (state: LoginState, { lang }) => {
    return {
      ...state,
      user: { lang } as User,
    };
  }),
  on(LoginActions.loginSuccess, (state: LoginState, { email }) => {
    return {
      ...state,
      user: {
        ...state.user,
        email,
      },
    };
  }),
  on(LoginActions.loginStatus, (state: LoginState, { isLoggedIn }) => {
    return {
      ...state,
      isLoggedIn,
    };
  })
);
