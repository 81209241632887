import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ActionButtonEnum } from '@models/form';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
  @HostBinding('class') class = 'mb-3 block';

  @Input() buttons: ActionButtonEnum[] = [];

  @Output() add = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();

  getButtonClass(label: ActionButtonEnum): string {
    const buttonClass = ['pi'];
    switch (label) {
      case ActionButtonEnum.Add:
        buttonClass.push('pi-plus');
        break;
      case ActionButtonEnum.Edit:
        buttonClass.push('pi-pencil');
        break;
    }
    return buttonClass.join(' ');
  }

  onClick(label: ActionButtonEnum): void {
    switch (label) {
      case ActionButtonEnum.Add:
        this.add.emit();
        break;
      case ActionButtonEnum.Edit:
        this.edit.emit();
        break;
    }
  }
}
