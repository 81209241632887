import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormBase } from '@util/form-base';
import { RoutesList } from '@models/routes';
import { User } from '@models/auth';
import { AuthService } from '@services/auth.service';
import { ToastService } from '@services/toast-service';
import { login } from '../store/login.action';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends FormBase implements OnInit {
  registerLink = `/${RoutesList.Register}`;
  recoveryLink = `/${RoutesList.Restore}`;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    private readonly store: Store,
    private toastService: ToastService,
    public router: Router,
    private authService: AuthService,
    protected translateService: TranslateService
  ) {
    super();
  }
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.formGroup.invalid) {
      return;
    }

    if (this.submitted) {
      const { email, password } = this.formGroup.value;
      const userData = {
        email,
        password,
        lang:
          this.translateService.currentLang ||
          this.translateService.defaultLang,
      } as User;

      this.store.dispatch(login(userData));
    }
  }
}
