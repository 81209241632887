import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SwVersionEventTypes } from '@models/service-worker';
import { PwaService } from '@services/pwa.service';
import { ToastService } from '@services/toast-service';
import { LocalStorageKey } from '@models/localstorage';
import { Severity } from '@models/toast';
import { loginStatus } from './login/store/login.action';
import { EventBusService } from '@services/event-bus/event-bus.service';
import { IEventBus } from '@models/event-bus';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isSpinnerVisible = false;
  private routeChangeSubsctibtion: Subscription;
  private eventBusSub: Subscription;

  constructor(
    private readonly store: Store,
    private swUpdate: SwUpdate,
    private pwa: PwaService,
    private activatedRoute: ActivatedRoute,
    private eventBusService: EventBusService,
    protected translateService: TranslateService,
    private toastService: ToastService
  ) {
    //Emits a `VersionDetectedEvent` event whenever a new version is detected on the server.
    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === SwVersionEventTypes.VersionDetected) {
        document.location.reload();
      }
    });
  }

  ngOnInit(): void {
    this.eventBusSub = this.eventBusService.on(
      IEventBus.Spinner,
      (isSpinnerVisible: boolean) => {
        this.isSpinnerVisible = isSpinnerVisible;
      }
    );
    this.pwa.updateOnlineStatus();
    this.checkExistedToken();
    this.routeChangeSubsctibtion = this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        params.lang ? this.translateService.use(params.lang) : '';
        if (params.message) {
          this.toastService.show({
            summary: 'Success',
            severity: Severity.Success,
            detail: params.message,
          });
        }
      }
    );
  }

  private checkExistedToken(): void {
    const token = localStorage.getItem(LocalStorageKey.Token);
    this.store.dispatch(loginStatus({ isLoggedIn: !!token }));
  }

  ngOnDestroy(): void {
    this.routeChangeSubsctibtion?.unsubscribe();
    this.eventBusSub?.unsubscribe();
  }
}
