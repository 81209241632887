<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div *ngIf="!restoreLink" class="mb-3">
    <p class="text-600 text-center font-medium">
      {{ "ENTER_EMAIL" | translate }}
    </p>
    <label for="userEmail" class="block text-900 text-xl font-medium mb-2">{{
      "EMAIL_ADDRESS" | translate
    }}</label>
    <input
      type="email"
      id="userEmail"
      placeholder="name@example.com"
      formControlName="email"
      pInputText
      class="w-full md:w-30rem"
      [ngClass]="{ 'ng-invalid': submitted && formControls.email.errors }"
      style="padding: 1rem"
    /><br />
    <ng-container
      *ngIf="formControls?.email?.value?.length && formControls.email.errors"
    >
      <small *ngIf="formControls.email.errors.required" class="p-error">
        {{ "EMAIL_REQUIRED" | translate }}
      </small>

      <small *ngIf="formControls.email.errors.email" class="p-error">
        {{ "EMAIL_MUST_BE_VALID" | translate }}
      </small>
    </ng-container>
  </div>

  <div *ngIf="restoreLink" class="mt-3 mb-3">
    <p class="text-600 text-center font-medium">
      {{ "COME_UP_NEW_PASSWORD" | translate }}
    </p>
    <label for="password" class="block text-900 font-medium text-xl mb-2">{{
      "PASSWORD" | translate
    }}</label>

    <!-- TODO: make common password component -->

    <p-password
      id="password"
      weakLabel="{{ 'WEAK_PASSWORD' | translate }}"
      mediumLabel="{{ 'MEDIUM_PASSWORD' | translate }}"
      strongLabel="{{ 'STRONG_PASSWORD' | translate }}"
      promptLabel="{{ 'PICK_PASSWORD' | translate }}"
      formControlName="password"
      placeholder="{{ 'PASSWORD' | translate }}"
      [toggleMask]="true"
      [ngClass]="{ 'ng-invalid': submitted && formControls.password.errors }"
      inputStyleClass="w-full p-3 md:w-30rem"
    >
      <ng-template pTemplate="footer">
        <p-divider></p-divider>
        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
          <li>{{ "LEAST_ONE_LETTER" | translate }}</li>
          <li>{{ "LEAST_ONE_NUMERIC" | translate }}</li>
          <li>{{ "MINIMUM_CHARACTERS" | translate }}</li>
        </ul>
      </ng-template></p-password
    >

    <div
      *ngIf="submitted && formControls.password.errors"
      class="invalid-feedback"
    >
      <small *ngIf="formControls.password.errors.required" class="p-error">
        {{ "PASSWORD_REQUIRED" | translate }}
      </small>
    </div>
  </div>
  <button
    type="submit"
    pButton
    pRipple
    [disabled]="!!formGroup.invalid"
    label="{{ 'RECOVERY' | translate }}"
    class="w-full p-3 text-xl"
  ></button>

  <div class="flex justify-content-end mt-3 text-600">
    <a
      class="font-medium no-underline ml-2 text-right cursor-pointer"
      style="color: var(--primary-color)"
      [routerLink]="[loginLink]"
      >{{ "LOGIN" | translate }}</a
    >
  </div>
</form>
