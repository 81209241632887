<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <label for="userEmail" class="block text-900 text-xl font-medium mb-2">{{
      "EMAIL_ADDRESS" | translate
    }}</label>
    <input
      type="email"
      id="userEmail"
      placeholder="name@example.com"
      formControlName="email"
      pInputText
      class="w-full md:w-30rem"
      [ngClass]="{ 'ng-invalid': submitted && formControls.email.errors }"
      style="padding: 1rem"
    /><br />
    <ng-container *ngIf="submitted && formControls.email.errors">
      <small *ngIf="formControls.email.errors.required" class="p-error">
        {{ "EMAIL_REQUIRED" | translate }}
      </small>

      <small *ngIf="formControls.email.errors.email" class="p-error">
        {{ "EMAIL_MUST_BE_VALID" | translate }}
      </small>
    </ng-container>
  </div>
  <div class="mt-3 mb-3">
    <label for="password" class="block text-900 font-medium text-xl mb-2">{{
      "PASSWORD" | translate
    }}</label>
    <p-password
      id="password"
      formControlName="password"
      placeholder="{{ 'PASSWORD' | translate }}"
      [toggleMask]="true"
      [feedback]="false"
      [ngClass]="{ 'ng-invalid': submitted && formControls.password.errors }"
      inputStyleClass="w-full p-3 md:w-30rem"
    ></p-password>
    <br />
    <ng-container *ngIf="submitted && formControls.password.errors">
      <small *ngIf="formControls.password.errors.required" class="p-error">
        {{ "PASSWORD_REQUIRED" | translate }}
      </small>
    </ng-container>
  </div>

  <div class="flex justify-content-end mb-3">
    <a class="small" [routerLink]="[recoveryLink]">{{
      "FORGOT_PASSWORD" | translate
    }}</a>
  </div>

  <button
    type="submit"
    pButton
    pRipple
    label="{{ 'SIGN_IN' | translate }}"
    class="w-full p-3 text-xl"
  ></button>

  <div class="flex justify-content-end mt-3 text-600">
    {{ "DO_NOT_HAVE_ACCOUNT" | translate }}
    <a
      class="font-medium no-underline ml-2 text-right cursor-pointer"
      style="color: var(--primary-color)"
      [routerLink]="[registerLink]"
      >{{ "SIGN_UP" | translate }}</a
    >
  </div>
</form>
