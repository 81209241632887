import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { LangsComponent } from './langs/langs.component';
import { CircleButtonComponent } from './buttons/circle-button/circle-button.component';
import { ActionButtonsComponent } from './buttons/action-buttons/action-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    DividerModule,
    RadioButtonModule,
    DropdownModule,
    ToastModule,
    InputSwitchModule,
    SidebarModule,
    TableModule,
    FormsModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    DialogModule,
    CardModule,
    ChartModule,
  ],
  declarations: [LangsComponent, CircleButtonComponent, ActionButtonsComponent],
  exports: [
    LangsComponent,
    CircleButtonComponent,
    ActionButtonsComponent,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    DividerModule,
    RadioButtonModule,
    DropdownModule,
    ToastModule,
    InputSwitchModule,
    SidebarModule,
    TableModule,
    SelectButtonModule,
    ProgressSpinnerModule,
    DialogModule,
    CardModule,
    ChartModule,
  ],
})
export class SharedModule {}
