import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppLanguage } from '@models/localization';

@Component({
  selector: 'app-langs',
  templateUrl: './langs.component.html',
})
export class LangsComponent implements OnInit {
  selectedLanguage!: IAppLanguage;

  languages: IAppLanguage[] = [IAppLanguage.English, IAppLanguage.Ukrainian];

  readonly LANGUAGE = IAppLanguage;

  get defaultLang(): IAppLanguage {
    return this.translateService.getDefaultLang() as IAppLanguage;
  }

  get currentLang(): IAppLanguage {
    return this.translateService.currentLang as IAppLanguage;
  }

  get enBtnClassList(): string[] {
    const classList = ['en'];
    if (this.selectedLanguage === IAppLanguage.English) {
      classList.push('active');
    }
    return classList;
  }

  get uaBtnClassList(): string[] {
    const classList = ['ua'];
    if (this.selectedLanguage === IAppLanguage.Ukrainian) {
      classList.push('active');
    }
    return classList;
  }

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const lang = this.currentLang || this.defaultLang;
    this.languages.forEach((language: IAppLanguage) => {
      if (language === lang) {
        this.translateService.currentLang = language;
        this.selectedLanguage = language;
      }
    });
  }

  onLangChange(lang: IAppLanguage): void {
    const defaultLang = this.translateService.defaultLang;
    this.translateService.use(lang ?? defaultLang);
    this.selectedLanguage = lang;
  }
}
