import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, switchMap, throwError, tap } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { EventData } from '@services/event-bus/event.class';
import { EventBusService } from '@services/event-bus/event-bus.service';
import { IAppLanguage } from '@models/localization';
import { IMessage, IResponse } from '@models/response';
import { IEventBus } from '@models/event-bus';
import { ToastService } from '@services/toast-service';
import { isLoggedIn } from './login/store/login.selectors';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private isRefreshing = false;
  private isLoggedIn = false;

  constructor(
    private readonly store: Store,
    private authService: AuthService,
    private eventBusService: EventBusService,
    private toastService: ToastService
  ) {
    this.store
      .select(isLoggedIn)
      .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.eventBusService.emit(new EventData(IEventBus.Spinner, true));

    const queryParamsLang = this.getQueryParamByKey(location.href, 'lang');
    const lang = req.body?.lang || queryParamsLang || IAppLanguage.English;
    req = req.clone({
      withCredentials: true,
      headers: req.headers
        .set('Authorization', `Bearer ${localStorage.getItem('token')}`)
        .set('Accept-Language', lang),
    });

    return next.handle(req).pipe(
      tap((response: HttpResponse<IResponse<any>>) => {
        if (
          !(response instanceof HttpErrorResponse) &&
          (response.status === 200 || response.status === 201)
        ) {
          this.eventBusService.emit(new EventData(IEventBus.Spinner, false));
          this.showToastMessage(response?.body?.message);
        }
      }),
      catchError(
        (response: { error: { message: IMessage }; status: number }) => {
          this.eventBusService.emit(new EventData(IEventBus.Spinner, false));
          if (
            response instanceof HttpErrorResponse &&
            !req.url.includes('user/login') &&
            response.status === 401
          ) {
            return this.handle401Error(req, next);
          } else if (response.status === 403) {
            // if the API returns 403 error (the refresh token is expired), emit 'logout' event.
            this.eventBusService.emit(new EventData(IEventBus.Logout, null));
          }

          return throwError(() => {
            this.showToastMessage(response?.error?.message);
          });
        }
      )
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      if (this.isLoggedIn) {
        return this.authService.refreshToken().pipe(
          switchMap((response) => {
            this.isRefreshing = false;

            localStorage.setItem('token', response.data.accessToken);
            request = request.clone({
              withCredentials: true,
              headers: request.headers.set(
                'Authorization',
                `Bearer ${response.data.accessToken}`
              ),
            });
            return next.handle(request).pipe(
              tap((response: HttpResponse<IResponse<any>>) => {
                if (!(response instanceof HttpErrorResponse)) {
                  this.showToastMessage(response?.body?.message);
                }
              })
            );
          }),
          catchError((error) => {
            this.showToastMessage(error?.error?.message);
            this.isRefreshing = false;
            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }

  private showToastMessage(message: IMessage): void {
    if (message) {
      this.toastService.show(message);
    }
  }

  private getQueryParamByKey(url: string, key: string): string | null {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);

    // Check if the given key exists in the query parameters
    if (params.has(key)) {
      return params.get(key); // Return the value of the key
    } else {
      return null; // Key not found, return null or handle as needed
    }
  }
}
