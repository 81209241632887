import { FormGroup } from '@angular/forms';
import { RoutesList } from '@models/routes';

export abstract class FormBase {
  formGroup: any = FormGroup;
  submitted = false;

  protected readonly ROUTES = RoutesList;

  get formControls() {
    return this.formGroup.controls;
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
