import { Injectable } from '@angular/core';
import { IAuth, User } from '@models/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, exhaustMap, tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { LoginActionType, loginStatus } from './login.action';
import { Router } from '@angular/router';
import { RoutesList } from '@models/routes';
import { IResponse } from '@models/response';
import { LocalStorageKey } from '@models/localstorage';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private readonly store: Store
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActionType.Login),
      exhaustMap((payload: User) =>
        this.authService.login(payload).pipe(
          map((res: IResponse<IAuth>) => {
            return { type: LoginActionType.LoginSuccess, ...res.data };
          }),
          tap((res) => {
            localStorage.setItem(LocalStorageKey.Token, res.accessToken);
            this.store.dispatch(loginStatus({ isLoggedIn: true }));
            this.router.navigate([RoutesList.Dashboard]);
          })
          //TODO: REMOVE
          // catchError((response) => {
          //   debugger;
          //   alert('wrong');
          //   return EMPTY;
          // })
        )
      )
    );
  });
}
