<div class="text-900 text-3xl font-medium mb-3 text-center">
  {{ "ABOUT_US" | translate }}
</div>

<div class="text-left">
  <ng-container *ngIf="currentLang === LANGUAGE.English">
    <p>
      Our comprehensive website serves as your all-in-one digital management
      hub. With our user-friendly interface, you can seamlessly handle
      notifications, maintain your contact list, and efficiently organize your
      to-do lists. Receive and tailor notifications from various sources to keep
      yourself well-informed, while also ensuring your contacts are neatly
      organized for easy access.
    </p>
    <p>
      What really sets us apart is our unique feature: the ability to create
      your own pages. We can design new pages for you with basic logic to suit
      your unique needs. Whether it's personal projects, work assignments, or
      specific interests, we can create pages that exactly meet your
      requirements. Our platform cares about the technical aspects, so you can
      focus on achieving your goals.
    </p>
    <p>
      Join us today and experience a digital management solution that not only
      simplifies your life but also evolves with you.
      <a
        class="font-medium cursor-pointer"
        style="color: var(--primary-color)"
        [routerLink]="[registerLink]"
        >Sign up now</a
      >
      to start utilizing the full potential of our platform.
    </p>
  </ng-container>

  <ng-container *ngIf="currentLang === LANGUAGE.Ukrainian">
    <p>
      Наш веб-сайт слугує вашим комплексним центром цифрового керування. Завдяки
      нашому зручному інтерфейсу ви можете без проблем cтворювати сповіщення,
      підтримувати список контактів і ефективно впорядковувати списки справ.
      Отримуйте та налаштовуйте сповіщення з різних джерел, щоб бути добре
      поінформованим, а також гарантуйте, що ваші контакти акуратно організовані
      для легкого доступу.
    </p>
    <p>
          </p>
    <p>
      Приєднуйтесь до нас сьогодні та відчуйте цифрове рішення для керування,
      яке не лише спрощує ваше життя, але й розвивається разом з вами.
      <a
        class="font-medium cursor-pointer"
        style="color: var(--primary-color)"
        [routerLink]="[registerLink]"
        >Зареєструйтеся зараз</a
      >, щоб почати використовувати весь потенціал нашої платформи.
    </p>
  </ng-container>
</div>
