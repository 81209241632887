import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Validators,
  FormBuilder,
  AbstractControlOptions,
  ValidatorFn,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormBase } from '@util/form-base';
import { AuthService } from '@services/auth.service';
import { RoutesList } from '@models/routes';
import { User } from '@models/auth';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormComponent extends FormBase implements OnInit {
  loginLink = `/${RoutesList.Login}`;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    public router: Router,
    private authService: AuthService,
    protected translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const formOptions: AbstractControlOptions = {
      validators: this.confirmedValidator(
        'password',
        'confirmPassword'
      ) as ValidatorFn,
    };

    this.formGroup = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        name: ['', [Validators.required]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      formOptions
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }
    if (this.submitted) {
      const { email, password, name } = this.formGroup.value;
      const userData = {
        email,
        name,
        password,
        lang:
          this.translateService.currentLang ||
          this.translateService.defaultLang,
      } as User;
      this.authService.register(userData).subscribe((data) => {
        this.router.navigate([RoutesList.Login]);
      });
    }
  }
}
