import { User } from '@models/auth';
import { createAction, props } from '@ngrx/store';

export enum LoginActionType {
  Login = '[Login page] login',
  LoginSuccess = '[Login page] login success',
  LoginStatus = '[Login page] login status',
}

export const login = createAction(LoginActionType.Login, props<User>());
export const loginSuccess = createAction(
  LoginActionType.LoginSuccess,
  props<User>()
);

export const loginStatus = createAction(
  LoginActionType.LoginStatus,
  props<{ isLoggedIn: boolean }>()
);
