import { environment } from 'environments/environment';

export class Constants {
  static readonly TOAST_DELAY = 3000;

  static readonly BASE_URL = `${environment.apiUrl}`;
  static readonly BASE_V1_URL = `${this.BASE_URL}/api/v1`;
  static readonly LOGIN_URL = `${this.BASE_V1_URL}/user/login`;
  static readonly LOGOUT_URL = `${this.BASE_V1_URL}/user/logout`;
  static readonly SIGNUP_URL = `${this.BASE_V1_URL}/user/signup`;
  static readonly REFRESH_URL = `${this.BASE_V1_URL}/user/refresh`;
  static readonly RESTORE_ACCESS_URL = `${this.BASE_V1_URL}/user/restore-access`;
  static readonly UPDATE_PASSWORD_URL = `${this.BASE_V1_URL}/user/update-password`;
  static readonly TODO_URL = `${this.BASE_V1_URL}/user/todo`; //remove
}
