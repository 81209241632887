export enum ActionButtonEnum {
  Add = 'Add',
  Edit = 'Edit',
}

export interface ICard {
  header: string;
  subHeader?: string;
  desc?: string;
  image?: string;
}
