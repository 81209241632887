import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../constants';
import { IAuth, IRegister, Logout, User } from '@models/auth';
import { IResponse } from '@models/response';
import { IAppLanguage } from '@models/localization';
import { LocalStorageKey } from '@models/localstorage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  login(payload: User): Observable<IResponse<IAuth>> {
    return this.httpClient.post<IResponse<IAuth>>(Constants.LOGIN_URL, payload);
  }

  logout(): Observable<Logout> {
    return this.httpClient.post<Logout>(Constants.LOGOUT_URL, {}).pipe(
      tap({
        next: () => {
          localStorage.removeItem(LocalStorageKey.Token); //TODO: move to effect
        },
      })
    );
  }

  /**
   * TODO: REMOVE!!!
   */
  getAll(): Observable<any> {
    return this.httpClient.get(Constants.TODO_URL, {});
  }

  register(payload: User): Observable<IResponse<IRegister>> {
    return this.httpClient.post<any>(Constants.SIGNUP_URL, payload);
  }

  restoreAccess(payload: {
    email: string;
    lang: IAppLanguage;
  }): Observable<IResponse<any>> {
    return this.httpClient.post<IResponse<any>>(
      Constants.RESTORE_ACCESS_URL,
      payload
    );
  }

  updateUserPassword(
    payload: {
      password: string;
      lang: IAppLanguage;
    },
    restoreLink: string
  ): Observable<IResponse<any>> {
    return this.httpClient.post<IResponse<any>>(
      `${Constants.UPDATE_PASSWORD_URL}/${restoreLink}`,
      payload
    );
  }

  refreshToken(): Observable<IResponse<IAuth>> {
    try {
      return this.httpClient.get<IResponse<IAuth>>(Constants.REFRESH_URL, {});
    } catch (e) {
      console.log('refreshToken ', e); //TODO:
    }
  }
}
