import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppLanguage } from '@models/localization';
import { RoutesList } from '@models/routes';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  readonly LANGUAGE = IAppLanguage;

  registerLink = `/${RoutesList.Register}`;

  get currentLang(): IAppLanguage {
    return this.translateService.currentLang as IAppLanguage;
  }

  constructor(private translateService: TranslateService) {}
}
