import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';
import { LoginPageComponent } from './login-page.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { AboutComponent } from './about/about.component';
import { RestorePasswordFormComponent } from './restore-password-form/restore-password-form.component';
import { FeatureStateKey } from '@models/store';
import { LoginEffects } from './store/login.effects';
import { loginReducer } from './store/login.reducer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginFormComponent,
    RegisterFormComponent,
    AboutComponent,
    RestorePasswordFormComponent,
  ],
  imports: [
    StoreModule.forFeature(FeatureStateKey.Login, loginReducer),
    EffectsModule.forFeature(LoginEffects),
    ReactiveFormsModule,
    CommonModule,
    LoginRoutingModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [LoginPageComponent],
})
export class LoginModule {}
