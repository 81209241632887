<app-circle-button
  (click)="onLangChange(LANGUAGE.Ukrainian)"
  [classList]="uaBtnClassList"
>
</app-circle-button>

<app-circle-button
  (click)="onLangChange(LANGUAGE.English)"
  [classList]="enBtnClassList"
>
</app-circle-button>

<!-- <button
  class="ua"
  [ngClass]="{ active: selectedLanguage === LANGUAGE.Ukrainian }"
  (click)="onLangChange(LANGUAGE.Ukrainian)"
></button>
<button
  class="en"
  [ngClass]="{ active: selectedLanguage === LANGUAGE.English }"
  (click)="onLangChange(LANGUAGE.English)"
></button> -->
