<span class="p-buttonset">
  <button
    *ngFor="let label of buttons"
    pButton
    pRipple
    [label]="label"
    [icon]="getButtonClass(label)"
    (click)="onClick(label)"
  ></button>
</span>
