import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoutesList } from '@models/routes';
import { LayoutService } from '@services/app.layout.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnDestroy {
  private url: string;
  private routeUrlSubscription: Subscription;

  value: any;

  justifyOptions: any[] = [
    { icon: 'pi pi-align-left', justify: 'Left' },
    { icon: 'pi pi-align-right', justify: 'Right' },
    { icon: 'pi pi-align-center', justify: 'Center' },
    { icon: 'pi pi-align-justify', justify: 'Justify' },
  ];

  readonly ROUTE = RoutesList;

  get isLoginPage(): boolean {
    return !!this.url?.includes(RoutesList.Login) || this.url === '/';
  }

  get isRegisterPage(): boolean {
    return !!this.url?.includes(RoutesList.Register);
  }

  get isRestorePage(): boolean {
    if (!!this.url?.includes(RoutesList.Restore)) {
      console.log('RestorePage');
    }
    return !!this.url?.includes(RoutesList.Restore);
  }

  get isAboutPage(): boolean {
    return !!this.url?.includes(RoutesList.About);
  }

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public layoutService: LayoutService
  ) {
    this.routeUrlSubscription = this.route.url.subscribe(
      () => (this.url = this.router.url)
    );
  }

  switchLoginPage(page: RoutesList): void {
    this.router.navigate([page]);
  }

  ngOnDestroy(): void {
    this.routeUrlSubscription?.unsubscribe();
  }
}
